
import React from 'react';

import ToolSettings from '../../../components/ToolSettings/ToolSettings';

import './TilemapToolSettings.scss';

class TilemapToolSettings extends React.Component {
  render() {
    return (
      <ToolSettings />
    );
  }
}

export default TilemapToolSettings;
